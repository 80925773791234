import { EstateAgent } from "../../../types";

export const getEstateAgent = (
  estateAgents: EstateAgent[],
  estateAgentIdSelected: string,
): EstateAgent | null => {
  return (
    estateAgents.find(
      (estateAgent) => estateAgent._id === estateAgentIdSelected,
    ) || null
  );
};

export const getVariant = (
  error: string | undefined,
  isDisabled: boolean = false,
): "error" | "disabled" | "enabled" => {
  if (error) return "error";
  return isDisabled ? "disabled" : "enabled";
};

export const getEstateAgentOptions = (
  estateAgents: EstateAgent[],
): {
  label: string;
  value: string;
  disabled?: boolean;
}[] => {
  return [
    { label: "Select your option", value: "", disabled: true },
    ...estateAgents
      .map((x) => ({
        label: `${x.brand} - ${x.office.name}`,
        value: x._id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
};

export const isEmailForPrimaryValuer = (
  estateAgentIdSelectedId: string,
  estateAgents: EstateAgent[],
): boolean => {
  const estateAgent = getEstateAgent(estateAgents, estateAgentIdSelectedId);

  if (estateAgent) {
    const primaryValuer = estateAgent.valuers.find(
      (valuer) => valuer.primary_valuer,
    );

    return !!primaryValuer && !!primaryValuer.email;
  }
  return false;
};
