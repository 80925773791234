import { TransactionsTable } from "./TransactionsTable";
import { Tabs } from "./TransactionsGroupDetails";
import { OpsDetails } from "./OpsDetails";

interface RenderTabContentProps {
  currentTab: Tabs;
}

export const RenderTabContent = ({
  currentTab,
}: RenderTabContentProps): React.ReactNode => {
  switch (currentTab) {
    case "details":
      return <TransactionsTable />;
    case "ops":
      return <OpsDetails />;
  }
};
