import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";

export type ThirdfortChecksResponse =
  paths["/api/thirdfort/{transactionId}"]["get"]["responses"]["200"]["content"]["application/json"];

export const findAllThirdfortChecks = async (
  transactionId: string,
): Promise<ThirdfortChecksResponse> => {
  try {
    const response = await api.get(`/thirdfort/${transactionId}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error("There was an error fetching the thirdfort checks");
  }
};
