import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AdminUser } from "../../pages/UserDetails/types";
import { Giftors } from "../../pages/TransactionDetails/types";
import { ThirdfortSellers } from "../Thirdfort/ThirdfortSellers";
import { ThirdfortPurchasers } from "../Thirdfort/ThirdfortPurchasers";
import { ThirdfortProvider } from "../../context/ThirdfortProvider";
import { ApiController } from "../../../service/ApiController";
import { useTransactionContext } from "../../context/TransactionProvider";

import ErrorText from "@/foundation/Typography/ErrorText";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";

export interface PurchaseIntentUser {
  buyers: AdminUser[];
  giftors: Giftors[];
}

export interface ThirdfortDetail {
  label: string;
  value: string | undefined;
}

export const ThirdfortTab = () => {
  const { id } = useParams();
  const { transaction } = useTransactionContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showV2, setShowV2] = useState(false);

  useEffect(() => {
    async function fetchAdminConfig() {
      try {
        setLoading(true);
        setError("");

        const adminConfig = await ApiController.findAdminConfig();

        setShowV2(
          new Date(adminConfig.thirdfort_v2_switchover_date) <
            new Date(transaction!.createdAt),
        );
      } catch {
        setError("Error fetching admin config");
      } finally {
        setLoading(false);
      }
    }

    fetchAdminConfig();
  }, [transaction]);

  if (!id)
    return <ErrorText>There was an error getting the transaction</ErrorText>;

  if (loading) {
    return <FullPageLoader />;
  }

  if (error) {
    return <ErrorText>{error}</ErrorText>;
  }

  return (
    <ThirdfortProvider transactionId={id}>
      <ThirdfortSellers showV2Sellers={showV2} />
      <ThirdfortPurchasers showV2Purchasers={showV2} />
    </ThirdfortProvider>
  );
};
