import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import Typography from "@/foundation/Typography/Typography";

import Icons, { TextInputIconProps } from "@/foundation/Icons/Icons";
import { formatCamelCaseString } from "@shared/utils";
import { Lozenge } from "@/components/Lozenge/Lozenge";
import { ApiController } from "../../../service/ApiController";
import { FetchTransactionTimelineResponse } from "../../../service/ApiController/fetchTransactionEventsTimeline";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { useTransactionContext } from "../../context/TransactionProvider";

type EventType = FetchTransactionTimelineResponse[0];
type EventName = EventType["event_name"];

const iconMapping: Partial<Record<EventName, TextInputIconProps>> = {
  account_active: "GreenTick",
  account_registered: "UserRegistered",
  additional_info_solicitor_email_sent: "EmailSent",
  estate_agent_assigned: "EstateAgentAssigned",
  estate_agent_assigned_email_sent: "EmailSent",
  further_details_solicitor_email_sent: "EmailSent",
  introduction_email_sent: "EmailSent",
  payment_successful: "PaymentSuccessful",
  purchase_intent_created: "PurchaseIntentCreated",
  purchase_intent_success_email_sent: "EmailSent",
  payment_failed: "Warning",
  plot_plan_request_email_sent: "EmailSent",
  solicitor_assigned: "SolicitorAssigned",
  referral_code_applied: "PaymentSuccessful",
  terms_accepted: "TermsAccepted",
  transaction_created: "TransactionCreated",
  transaction_success_email_sent: "EmailSent",
};

const SELLER_ICON = "S";
const BUYER_ICON = "B";

const getEventIcon = (eventName: EventName) => {
  return iconMapping[eventName] || "GreenTick";
};

export const EventsTimeline = () => {
  const { transaction } = useTransactionContext();
  const [displayAllEvents, setDisplayAllEvents] = useState(false);
  const [allEvents, setAllEvents] = useState<FetchTransactionTimelineResponse>(
    [],
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchEvents() {
      setLoading(true);
      setError("");

      try {
        if (!transaction) {
          throw new Error("Transaction not found");
        }

        const eventsRes = await ApiController.fetchTransactionEventsTimeline(
          transaction._id,
        );

        setAllEvents(eventsRes);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
        setError("An unkown error occured");
      }

      setLoading(false);
    }

    fetchEvents();
  }, [transaction]);

  const eventsToDisplay = useMemo(
    () => (displayAllEvents ? allEvents : allEvents.slice(0, 5)),
    [allEvents, displayAllEvents],
  );

  const getRoleIcon = (event: EventType): string => {
    const { event_name, author, type } = event;
    const primarySeller = transaction?.sellers.find(
      (seller) => seller.primary_customer,
    );

    if (
      type === "seller" ||
      (["account_active", "account_registered"].includes(event_name) &&
        author === primarySeller?.user._id)
    ) {
      return SELLER_ICON;
    } else {
      return BUYER_ICON;
    }
  };

  return (
    <>
      <div className="relative">
        <div className="absolute left-[11px] top-[24px] bottom-0 w-[2px] bg-brand-heavy-warmth-75"></div>
        {loading && <FullPageLoader />}
        {error && (
          <Typography
            type="p"
            variant="lg"
            weight="bold"
            className="text-ui-black-75"
          >
            {error}
          </Typography>
        )}
        {!error &&
          !loading &&
          eventsToDisplay.map((event) => (
            <div key={event._id}>
              <div className="flex items-center relative">
                {event.event_name in iconMapping ? (
                  <Icons iconName={getEventIcon(event.event_name)} size="24" />
                ) : (
                  <span className="bg-brand-heavy-teal-100 flex justify-center items-center rounded-full h-[16px] w-[16px] ml-[4px]"></span>
                )}

                <Typography
                  type="span"
                  variant="lg"
                  weight="bold"
                  className="bg-brand-heavy-warmth-100 text-brand-heavy-teal-100 flex justify-center items-center rounded-full h-[30px] w-[30px] mx-[12px]"
                >
                  {getRoleIcon(event)}
                </Typography>

                <Typography
                  type="p"
                  variant="lg"
                  weight="bold"
                  className="mr-[12px]"
                >
                  {formatCamelCaseString(event.event_name)}
                </Typography>

                {event.milestone && (
                  <Lozenge
                    fontColor="text-ui-white-100"
                    backgroundColor="bg-ui-success-100"
                  >
                    Milestone
                  </Lozenge>
                )}
              </div>

              <div className="flex items-center mt-[8px] ml-[65px] mb-[32px] ">
                <Typography type="p" variant="base" className="ml-[12px]">
                  {format(new Date(event.date_time), "dd/MM/yyyy, HH:mm")}
                </Typography>

                <Typography
                  type="p"
                  variant="base"
                  className="mx-[12px] text-ui-black-75"
                >
                  |
                </Typography>

                <Typography
                  type="p"
                  variant="base"
                  className="text-ui-black-75"
                >
                  Author: {event.authorName}
                </Typography>

                {event.comments && (
                  <>
                    <Typography
                      type="p"
                      variant="base"
                      className="text-ui-black-75 mx-[12px] "
                    >
                      |
                    </Typography>
                    <Typography
                      type="p"
                      variant="base"
                      className="text-ui-black-75"
                    >
                      {event.comments}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>

      {allEvents.length > 5 && (
        <button
          className="underline cursor-pointer text-brand-heavy-teal-75 my-[16px]"
          onClick={() => setDisplayAllEvents(!displayAllEvents)}
          type="button"
        >
          <Typography
            variant="base"
            weight="regular"
            type="span"
            className="text-brand-heavy-teal-75"
          >
            {displayAllEvents ? "Show less ↑" : "All events"}
          </Typography>
        </button>
      )}
    </>
  );
};
