import { z } from "zod";
import validator from "validator";

import { mortgageProvidersOptions } from "../components/Form/DetailsForm/Fields";
import { refineMobileNumber } from "./helpers";
import { EMAIL_ADDRESS } from "../constants";

import { SignUpSchema, SignUpType } from "./SignUpSchema";
import { SignInSchema, SignInType } from "./SignInSchema";
import {
  companySellerSchema,
  groupTransactionsSchema,
  manualTransactionPropertySchema,
  manualTransactionSchema,
  manualTransactionsSchema,
  transactionsGroupSchema,
} from "./TransactionsGroupSchema";

export { SignUpSchema, SignInSchema };
export type { SignUpType, SignInType };

export const AddressSchema = z.object({
  line_1: z
    .string()
    .min(2, { message: "Address must be at least 2 characters long" })
    .max(255, { message: "Address must be less than 255 characters long" }),
  line_2: z.string().nullish(),
  line_3: z.string().nullish(),
  post_town: z
    .string()
    .min(2, { message: "Town must be at least 2 characters long" })
    .max(255, { message: "Town must be less than 255 characters long" }),
  country: z
    .string()
    .min(2, { message: "Country must be at least 2 characters long" })
    .max(255, { message: "Country must be less than 255 characters long" }),
  postcode: z.string().refine((str) => validator.isPostalCode(str, "GB"), {
    message: "Invalid postcode",
  }),
  county: z.string().nullish(),
  uprn: z.string().nullish(),
  district: z.string().nullish(),
  building_number: z.string().nullish(),
  sub_building_name: z.string().nullish(),
  building_name: z.string().nullish(),
  premise: z.string().nullish(),
  organisation_name: z.string().nullish(),
  po_box: z.string().nullish(),
});

export const PersonalDetailsSchema = z.object({
  firstName: z
    .string()
    .min(2, { message: "First name must be at least 2 characters long" })
    .max(255, { message: "First name must be less than 255 characters long" }),
  middleName: z.string().optional(),
  lastName: z
    .string()
    .min(2, { message: "Last name must be at least 2 characters long" })
    .max(255, { message: "Last name must be less than 255 characters long" }),
  email: z.string().email(),
  mobileNumber: z
    .string()
    .refine((phoneNumber) => refineMobileNumber(phoneNumber), {
      message: "Invalid mobile number",
    }),
  termsAndConditions: z.boolean(),
  primaryCustomer: z.boolean(),
});

const customerType = z
  .enum(["individual_owner", "representative"])
  .nullish()
  .refine(
    (customerType) => {
      return customerType !== null;
    },
    {
      message: "Please select an option",
    },
  );

export const PersonalDetailsWithAddressSchema = z.object({
  personId: z.string(),
  firstName: PersonalDetailsSchema.shape.firstName,
  middleName: PersonalDetailsSchema.shape.middleName.optional(),
  lastName: PersonalDetailsSchema.shape.lastName,
  email: PersonalDetailsSchema.shape.email,
  mobileNumber: PersonalDetailsSchema.shape.mobileNumber,
  termsAndConditions: PersonalDetailsSchema.shape.termsAndConditions,
  primaryCustomer: PersonalDetailsSchema.shape.primaryCustomer,
  customerType: customerType,
  correspondenceAddress: AddressSchema,
});

export const SellerPropertyDetailsSchema = z
  .object({
    _id: z.string().optional(),
    propertyAddress: AddressSchema,
    askingPrice: z.string().refine(
      (price) => {
        const numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas and convert to float
        return !isNaN(numericPrice) && numericPrice >= 99000;
      },
      {
        message: "Asking price must be higher than £99,000",
      },
    ),
    tenureOption: z
      .enum(["Freehold", "Leasehold", "Share Of Freehold", "Other / Not Sure"])
      .nullish()
      .refine((tenureOption) => tenureOption !== null, {
        message: "Please select an option",
      }),
    tenureDetails: z.string().optional(),
    hasMortgage: z
      .boolean()
      .nullish()
      .refine((hasMortgage) => hasMortgage !== null, {
        message: "Please select an option",
      }),
    onwardPurchase: z
      .boolean()
      .nullish()
      .refine((onwardPurchase) => onwardPurchase !== null, {
        message: "Please select an option",
      }),
    customerType: customerType,
    additionalSellers: z.array(PersonalDetailsWithAddressSchema),
    isAnotherSellerSelected: z
      .boolean()
      .nullish()
      .refine((isAnotherSellerSelected) => isAnotherSellerSelected !== null, {
        message: "Please select an option",
      }),
  })
  .refine(
    (data) => {
      const isNotValid =
        data.customerType === "representative" &&
        data.additionalSellers.length < 1;

      return !isNotValid;
    },
    {
      message:
        "If you are acting on behalf of the seller, please select yes and add the seller’s details",
      path: ["isAnotherSellerSelected"],
    },
  )
  .refine(
    (data) => {
      const isNotValid =
        data.tenureOption === "Other / Not Sure" && !data.tenureDetails;

      return !isNotValid;
    },
    {
      message: "Please provide details",
      path: ["tenureDetails"],
    },
  );

export const FundingSchema = z
  .object({
    savingsFromEmployment: z.boolean(),
    gifts: z
      .enum(["yes", "no", "not_sure"])
      .nullish()
      .refine((value) => value !== null, {
        message: "Please select an option",
      }),
    remortgage: z.boolean(),
    investmentPortfolio: z.boolean(),
    inheritance: z.boolean(),
    divorceSettlement: z.boolean(),
    pensionLumpSumWithdrawal: z.boolean(),
    saleOfShares: z.boolean(),
    directorsLoansDividends: z.boolean(),
    otherFunding: z.boolean(),
    otherFundingOption: z.string().optional(),
    otherFundingComments: z.string().optional(),
  })
  .refine(
    (data) => {
      const isNotValid = data.otherFunding === true && !data.otherFundingOption;
      return !isNotValid;
    },
    {
      message: "Please provide details",
      path: ["otherFundingOption"],
    },
  );

export const MortgageProviderSchema = z
  .enum([...mortgageProvidersOptions])
  .optional();

export const GiftorSchema = z.object({
  personId: z.string(),
  amount: z.string().refine(
    (amount) => {
      const numericAmount = parseFloat(amount.replace(/,/g, "")); // Remove commas and convert to float
      return !isNaN(numericAmount) && numericAmount > 4;
    },
    {
      message: "Amount must be higher than £0",
    },
  ),
  firstName: PersonalDetailsSchema.shape.firstName,
  middleName: PersonalDetailsSchema.shape.middleName,
  lastName: PersonalDetailsSchema.shape.lastName,
  email: PersonalDetailsSchema.shape.email,
  mobileNumber: PersonalDetailsSchema.shape.mobileNumber,
  relationship: z
    .enum(["Parent", "Other Family", "Other"])
    .nullish()
    .refine((relationship) => relationship !== undefined, {
      message: "Please select an option",
    }),
  correspondenceAddress: AddressSchema,
});

export const BuyerPropertyDetailsSchema = z
  .object({
    _id: z.string(),
    property_id: z
      .string()
      .min(2, { message: "Please select an address" })
      .max(255, { message: "Please select an address" })
      .refine((value) => value !== "property_not_listed", {
        message: `Sorry, we couldn't find your property in our database. Before you continue, please contact our team at ${EMAIL_ADDRESS.SUPPORT}`,
      }),
    propertyAddress: AddressSchema.optional(),
    agreedPrice: z.string().refine(
      (price) => {
        const numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas and convert to float
        return !isNaN(numericPrice) && numericPrice >= 99999;
      },
      {
        message: "Agreed price must be higher than £99,999",
      },
    ),
    mortgageRequiredForPurchase: z
      .enum(["yes", "no", "not_sure"])
      .nullish()
      .refine((value) => value !== null, {
        message: "Please select an option",
      }),
    mortgageProvider: MortgageProviderSchema.optional(),
    giftors: z.array(GiftorSchema),
    funding: FundingSchema,
    purchaseDependentOnSale: z
      .boolean()
      .nullish()
      .refine((value) => value !== null, {
        message: "Please select an option",
      }),
    ownershipType: z
      .enum(["Sole", "Joint"])
      .nullish()
      .refine((value) => value !== null, {
        message: "Please select an option",
      }),
    firstTimeBuyer: z
      .boolean()
      .nullish()
      .refine((value) => value !== null, {
        message: "Please select an option",
      }),
    customerType: z.enum(["individual_owner", "representative"]).optional(),
    additionalBuyers: z.array(PersonalDetailsWithAddressSchema),
  })
  .refine(
    (data) => {
      const isNotValid =
        data.ownershipType === "Joint" && data.additionalBuyers.length < 1;

      return !isNotValid;
    },
    {
      message: "Please add at least one buyer if you are a joint owner",
      path: ["ownershipType"],
    },
  )
  .refine(
    (data) => {
      const isNotValid =
        data.mortgageRequiredForPurchase === "yes" && !data.mortgageProvider;

      return !isNotValid;
    },
    {
      message: "Please select an option",
      path: ["mortgageProvider"],
    },
  );

export const ContactFormSchema = z.object({
  fullName: z.string().min(2, { message: "Full name is required" }),
  mobileNumber: z
    .string()
    .refine((phoneNumber) => refineMobileNumber(phoneNumber), {
      message: "Invalid mobile number",
    })
    .nullish(),
  email: z
    .string()
    .email("Invalid email address")
    .min(2, { message: "Email is required" }),
  message: z.string().min(2, { message: "Message is required" }),
});

export type CustomerType = z.infer<typeof customerType>;
export type PersonalDetailsState = z.infer<typeof PersonalDetailsSchema>;
export type SellerPropertyDetailsState = z.infer<
  typeof SellerPropertyDetailsSchema
>;
export type PersonalDetailsWithAddressState = z.infer<
  typeof PersonalDetailsWithAddressSchema
>;
export type BuyerPropertyDetailsState = z.infer<
  typeof BuyerPropertyDetailsSchema
>;
export type GiftorSchemaState = z.infer<typeof GiftorSchema>;
export type AddressState = z.infer<typeof AddressSchema>;
export type MortgageProviderState = z.infer<typeof MortgageProviderSchema>;
export type CompanySellerState = z.infer<typeof companySellerSchema>;
export type ManualTransactionState = z.infer<typeof manualTransactionSchema>;
export type ManualTransactionsState = z.infer<typeof manualTransactionsSchema>;

export type ManualTransactionPropertyState = z.infer<
  typeof manualTransactionPropertySchema
>;
export type GroupTransactionsState = z.infer<typeof groupTransactionsSchema>;
export type TransactionsGroupState = z.infer<typeof transactionsGroupSchema>;
