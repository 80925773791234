import { useState } from "react";

import Typography from "@/foundation/Typography/Typography";
import { Button } from "@/components/Button/Button";
import { AssignEstateAgentBlock } from "../../components/AssignEstateAgent/AssignEstateAgentBlock";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import Icons from "@/foundation/Icons/Icons";

export const OpsDetails = () => {
  const { transactionsGroup } = useTransactionsGroupContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="flex items-center gap-[8px]">
        {transactionsGroup?.estateAgent && (
          <Icons iconName="GreenTick" size="24" />
        )}
        <Typography
          variant="lg"
          weight="bold"
          className="text-brand-heavy-teal-100"
        >
          Step 1:
        </Typography>
        <Typography variant="lg" className="text-brand-heavy-teal-100">
          Assign estate agent to all transactions in this group
        </Typography>
      </div>

      <div className="mt-[24px]">
        <Button
          type="button"
          onClick={() => setIsModalOpen(true)}
          variant="primary"
          size="lg"
          disabled={transactionsGroup?.estateAgent !== undefined}
        >
          Assign Estate Agent
        </Button>
      </div>

      {isModalOpen && (
        <AssignEstateAgentBlock
          onCloseModal={() => setIsModalOpen(false)}
          isTransactionsGroup={true}
        />
      )}
    </>
  );
};
