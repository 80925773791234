import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { operations } from "@shared/types.d";

type TransactionsGroupsApiResponse =
  operations["TransactionsGroupController_getTransactionsGroupByName"]["responses"]["200"]["content"]["application/json"];

export const findTransactionsGroup = async (
  name: string,
): Promise<TransactionsGroupsApiResponse> => {
  try {
    const response = await api.get(`/transactions-group/${name}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("There was an error finding transactions group");
  }
};
