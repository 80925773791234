import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { ApiController } from "../../service/ApiController";
import { ThirdfortCheck } from "../../types";
import { CreateThirdfortRequestBody } from "../service/AdminApiController/createThirdfortCheck";

interface ThirdfortContext {
  thirdfortChecks?: ThirdfortCheck[];
  abortCheck?: (thirdfortId: string) => void;
  orderCheck?: (
    params: CreateThirdfortRequestBody,
  ) => Promise<{ error?: string }>;
  reorderCheck?: (thirdfortId: string) => Promise<{ error?: string }>;
  loading?: boolean;
  error?: string;
}

export const ThirdfortContext = createContext<ThirdfortContext>({});

export const useThirdfortContext = () => {
  const context = useContext(ThirdfortContext);
  if (!context) {
    throw new Error(
      "useThirdfortContext must be used within a ThirdfortProvider",
    );
  }
  return context;
};

interface ThirdfortProviderProps {
  children: React.ReactNode;
  transactionId: string;
}

export const ThirdfortProvider = ({
  children,
  transactionId,
}: ThirdfortProviderProps) => {
  const [thirdfortChecks, setThirdfortChecks] = useState<ThirdfortCheck[]>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getThirdfortChecks = async (id: string) => {
    try {
      setError("");
      setLoading(true);

      const res = await ApiController.findAllThirdfortChecks(id);
      setThirdfortChecks(res);
    } catch (error) {
      Sentry.captureException(error);
      setError("There was an error while fetching the estate agent details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getThirdfortChecks(transactionId);
  }, [transactionId]);

  const abortCheck = async (id: string) => {
    try {
      setLoading(true);
      setError("");

      await ApiController.abortThirdfortCheck(id);
      await getThirdfortChecks(transactionId);
    } catch {
      setError("Error trying to abort thirdfort check");
    } finally {
      setLoading(false);
    }
  };

  const reorderCheck = async (id: string): Promise<{ error?: string }> => {
    try {
      setLoading(true);

      await ApiController.reorderThirdfortCheck(id);
      await getThirdfortChecks(transactionId);

      return {};
    } catch {
      return { error: "Error trying to reorder thirdfort check" };
    } finally {
      setLoading(false);
    }
  };

  const orderCheck = async (
    params: CreateThirdfortRequestBody,
  ): Promise<{ error?: string }> => {
    try {
      setLoading(true);

      await ApiController.createThirdfortCheck(transactionId, params);
      await getThirdfortChecks(transactionId);

      return {};
    } catch {
      return { error: "Error trying to order thirdfort check" };
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThirdfortContext.Provider
      value={{
        thirdfortChecks,
        abortCheck,
        orderCheck,
        reorderCheck,
        loading,
        error,
      }}
    >
      {children}
    </ThirdfortContext.Provider>
  );
};
