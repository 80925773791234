import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";

export const updateEstateAgentTransactionsGroup = async (
  groupId: string,
  estateAgentId: string,
): Promise<void> => {
  try {
    await api.put(`/transaction/estate-agent/${groupId}`, { estateAgentId });
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      `There was an error updating the estate agent at transactions group: ${error}`,
    );
  }
};
