import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import { Button } from "@/components/Button/Button";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import TextInput from "@/components/Input/TextInput";

export const stripeTransactionIdSchema = z.object({
  stripe_transaction_id: z.string().optional(),
});

export const AddPaymentDetails = () => {
  const { stripeTransactionId, setStripeTransactionId } =
    useTransactionsGroupContext();

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={{ stripe_transaction_id: stripeTransactionId || "" }}
        onSubmit={(values) =>
          setStripeTransactionId(values.stripe_transaction_id)
        }
        validate={withZodSchema(stripeTransactionIdSchema)}
      >
        {({ values, handleSubmit, handleChange, errors }) => {
          return (
            <div className="flex flex-col gap-[16px] md:flex-row md:flex-wrap">
              <TextInput
                className="md:w-[320px]"
                name="stripe_transaction_id"
                variant={errors["stripe_transaction_id"] ? "error" : "enabled"}
                label="Stripe Transaction Id"
                onChange={(newVal) => {
                  handleChange(newVal);
                }}
                value={values.stripe_transaction_id}
              />

              <Button
                size="2xl"
                variant="primary"
                type="button"
                disabled={!values.stripe_transaction_id}
                onClick={() => handleSubmit()}
                className="w-full md:w-[200px] h-[64px] md:mb-[0px] mb-[16px]"
              >
                Save
              </Button>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
