import { Route, Routes } from "react-router-dom";

import { PageNotFound } from "../../pages/PageNotFound/PageNotFound";
import { Users } from "../pages/Users/Users";
import { UserDetails } from "../pages/UserDetails/UserDetails";
import { Transactions } from "../pages/Transactions/Transactions";
import { TransactionDetails } from "../pages/TransactionDetails/TransactionDetails";
import { AdminDashboard } from "../pages/AdminDashboard/AdminDashboard";
import { Config } from "../pages/Config/Config";
import { EstateAgents } from "../pages/EstateAgents/EstateAgents";
import { EstateAgentDetails } from "../pages/EstateAgentDetails/EstateAgentDetails";
import { EstateAgentAdd } from "../pages/EstateAgentAdd/EstateAgentAdd";
import { EstateAgentBrands } from "../pages/EstateAgentBrands/EstateAgentBrands";
import { LawFirms } from "../pages/LawFirms/LawFirms";
import { LawFirmDetails } from "../pages/LawFirmDetails/LawFirmDetails";
import { LawyerGroupDetails } from "../pages/LawyerGroupDetails/LawyerGroupDetails";
import { AdminPageTemplate } from "../components/AdminPageTemplate/AdminPageTemplate";
import { TransactionsGroups } from "../pages/TransactionsGroups/TransactionsGroups";
import { TransactionsGroupDetails } from "../pages/TransactionsGroupDetails/TransactionsGroupDetails";
import { TransactionsGroupAdd } from "../pages/TransactionsGroupAdd/TransactionsGroupAdd";
import { TransactionsGroupSuccess } from "../pages/TransactionsGroupSuccess/TransactionsGroupSuccess";

export const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminPageTemplate />}>
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<UserDetails />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="transactions/:id" element={<TransactionDetails />} />
        <Route path="transactions-groups" element={<TransactionsGroups />} />
        <Route
          path="transactions-groups/add"
          element={<TransactionsGroupAdd />}
        />
        <Route
          path="transactions-groups/:id"
          element={<TransactionsGroupDetails />}
        />
        <Route
          path="transactions-groups/success"
          element={<TransactionsGroupSuccess />}
        />
        <Route path="estate-agents" element={<EstateAgents />} />
        <Route path="estate-agents/add" element={<EstateAgentAdd />} />
        <Route path="estate-agents/:id" element={<EstateAgentDetails />} />
        <Route path="estate-agent-brands" element={<EstateAgentBrands />} />
        <Route path="law-firms" element={<LawFirms />} />
        <Route path="law-firms/:id" element={<LawFirmDetails />} />
        <Route path="lawyer-groups/:id" element={<LawyerGroupDetails />} />
        <Route path="config" element={<Config />} />
        <Route path="/" element={<AdminDashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
