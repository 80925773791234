import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ApiController } from "../../../service/ApiController";
import { Table } from "@/components/Table/Table";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import ErrorText from "@/foundation/Typography/ErrorText";

import { formatCurrency, getAddressAsString } from "@shared/utils";
import { EstateAgent } from "../../../types";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { TransactionsGroupState } from "@schema";

interface TransactionsRow {
  _id: string;
  propertyAddress: string;
  askingPrice: string;
  estateAgent?: EstateAgent;
}

interface TransactionsTable {
  transactionsGroupId?: string;
}

export const TransactionsTable = ({
  transactionsGroupId,
}: TransactionsTable) => {
  const navigate = useNavigate();
  const { setTransactionsGroup } = useTransactionsGroupContext();

  const [transactionInfo, setTransactionInfo] = useState<TransactionsRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { id: routeId } = useParams<{ id: string }>();
  const groupId = transactionsGroupId || routeId;

  const columns = useMemo(
    () => [
      { key: "propertyAddress", title: "Property Address" },
      { key: "askingPrice", title: "Asking Price" },
    ],
    [],
  );

  const displayedTransactions = useMemo(() => {
    return transactionInfo.map(({ _id, propertyAddress, askingPrice }) => ({
      data: {
        _id,
        propertyAddress,
        askingPrice,
      },
      onClick: () => navigate(`/admin/transactions/${_id}`),
    }));
  }, [transactionInfo, navigate]);

  useEffect(() => {
    const errorMsg = "There was an error while fetching transactions groups";

    const getTransactionsFromGroup = async () => {
      if (!groupId) {
        setError(`${errorMsg}. No valid group ID provided.`);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError("");

      try {
        const transactions = await ApiController.findAllTransactions({
          groupId,
        });

        if (transactions) {
          const transactionInfo = transactions.map((transaction) => {
            const { _id, property_id, asking_price, estate_agent } =
              transaction;

            return {
              _id,
              propertyAddress: getAddressAsString(property_id.address, true),
              askingPrice: formatCurrency(asking_price, true, true),
              estateAgent: estate_agent,
            };
          });

          setTransactionInfo(transactionInfo);

          const hasEstateAgent = transactionInfo.every((transaction) =>
            Boolean(transaction.estateAgent),
          );

          if (hasEstateAgent) {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              estateAgent: transactionInfo[0].estateAgent,
            }));
          } else {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              estateAgent: undefined,
            }));
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(errorMsg);
        }
      } finally {
        setLoading(false);
      }
    };

    getTransactionsFromGroup();
  }, [groupId, setTransactionsGroup]);

  return (
    <>
      {loading && <FullPageLoader />}
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <Table columns={columns} rows={displayedTransactions} />
      )}
    </>
  );
};
