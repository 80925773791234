import { useState } from "react";
import { Formik, FormikErrors } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import { Button } from "@/components/Button/Button";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { ApiController } from "../../../service/ApiController";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Spinner } from "../../../components/Spinner/Spinner";
import TextInput from "@/components/Input/TextInput";

interface AddTransactionsGroupNameProps<T> {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  setDisplayGroupNameForm: (value: boolean) => void;
  submitTrigger: (trigger: () => void) => void;
  onError: (errors: FormikErrors<T> | undefined) => void;
}

export const transactionsGroupSchema = z.object({
  name: z.string().min(1, "Transactions group name is required"),
});

export const AddTransactionsGroupName = <T extends object>({
  isLoading,
  setIsLoading,
  setDisplayGroupNameForm,
  submitTrigger,
  onError,
}: AddTransactionsGroupNameProps<T>) => {
  const { setTransactionsGroup, transactionsGroup } =
    useTransactionsGroupContext();

  const [error, setError] = useState<string>("");

  const handleAddTransactionsGroupName = async (values: { name: string }) => {
    setIsLoading(true);

    try {
      const existingTransactionsGroup =
        await ApiController.findTransactionsGroup(values.name);

      if (existingTransactionsGroup) {
        setError(
          `Transactions group already exists with name "${values.name}"`,
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        setError("");
        setTransactionsGroup(values);
        setDisplayGroupNameForm(false);
      } else {
        setError("There was an error while processing transactions group");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Formik
        initialValues={{ name: transactionsGroup?.name || "" }}
        onSubmit={(values) => handleAddTransactionsGroupName(values)}
        validate={withZodSchema(transactionsGroupSchema)}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          touched,
          validateForm,
          setFieldTouched,
          submitForm,
        }) => {
          if (submitTrigger) {
            submitTrigger(async () => {
              try {
                const validationErrors = await validateForm();

                if (Object.keys(validationErrors).length > 0) {
                  onError(validationErrors as FormikErrors<T>);
                  Object.keys(validationErrors).forEach((key) => {
                    setFieldTouched(key, true);
                  });
                } else {
                  onError(undefined);
                  await submitForm();
                }
              } catch {
                onError({} as FormikErrors<T>);
              }
            });
          }
          return (
            <>
              <div className="flex flex-col gap-[16px] md:flex-row md:flex-wrap">
                <TextInput
                  className="md:w-[320px]"
                  name="name"
                  variant={error ? "error" : "enabled"}
                  label="Name"
                  onChange={(newVal) => {
                    handleChange(newVal);
                    setError("");
                  }}
                  value={values.name}
                />

                <Button
                  size="2xl"
                  variant="primary"
                  type="button"
                  disabled={!values.name}
                  onClick={() => handleSubmit()}
                  className="w-full md:w-[200px] h-[64px] md:mb-[0px] mb-[16px]"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <span className="flex items-center">
                        <Spinner /> Processing...
                      </span>
                    ) : (
                      <div>Save</div>
                    )}
                  </span>
                </Button>
              </div>
              {error && <ErrorText>{error}</ErrorText>}
              {errors.name && touched.name && (
                <ErrorText>{errors.name}</ErrorText>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
