import * as Sentry from "@sentry/react";

import { api } from "../../../service/ApiController";
import { paths } from "@shared/types.d";

export type CreateManualTransactionsRequest =
  paths["/api/transaction/manual"]["post"]["requestBody"]["content"]["application/json"];

export type CreateManualTransactionsResponse =
  paths["/api/transaction/manual"]["post"]["responses"]["201"]["content"]["application/json"];

export const createManualTransactions = async (
  body: CreateManualTransactionsRequest,
): Promise<CreateManualTransactionsResponse> => {
  try {
    const response = await api.post("/transaction/manual", body);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Create manual transactions failed");
  }
};
