import { useEffect, useState } from "react";

import { useThirdfortContext } from "../../context/ThirdfortProvider";
import { useTransactionContext } from "../../context/TransactionProvider";
import { Table, TableRow } from "@/components/Table/Table";
import { Card } from "@/components/Card/Card";
import { Button } from "@/components/Button/Button";
import ErrorText from "@/foundation/Typography/ErrorText";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { CreateThirdfortRequestBody } from "../../service/AdminApiController/createThirdfortCheck";

interface ThirdfortTableProps {
  persons: CreateThirdfortRequestBody[];
  cardTitle: string;
  actionButtonsDisabled?: boolean;
}

interface ThirdfortRow {
  name: string;
  status: string;
  action?: "order" | "reorder" | "abort";
  _id?: string;
  thirdfortPersonDetails: CreateThirdfortRequestBody;
}

export const ThirdfortTable = ({
  persons,
  cardTitle,
  actionButtonsDisabled,
}: ThirdfortTableProps) => {
  const { transaction } = useTransactionContext();
  const {
    thirdfortChecks,
    abortCheck,
    orderCheck,
    reorderCheck,
    error: thirdfortError,
    loading,
  } = useThirdfortContext();

  const [error, setError] = useState<string>("");

  const [rows, setRows] = useState<TableRow<ThirdfortRow>[]>([]);

  const onActionButtonClick = async (item: ThirdfortRow) => {
    setError("");
    if (item.action === "abort") {
      if (!item._id) {
        setError("Error getting check id, cannot abort check");
        return;
      }

      await abortCheck!(item._id);
    } else if (item.action === "order") {
      const { error } = await orderCheck!(item.thirdfortPersonDetails);

      if (error) {
        setError(error);
      }
    } else if (item.action === "reorder") {
      if (!item._id) {
        setError("Error getting check id, cannot reorder check");
        return;
      }

      const { error } = await reorderCheck!(item._id);
      if (error) {
        setError(error);
      }
    }
  };

  const columns = [
    { key: "name", title: "Name" },
    { key: "status", title: "Status" },
    {
      key: "action",
      title: "Action",
      rowCustomRender: (item: ThirdfortRow) => (
        <Button
          variant="link"
          onClick={() => onActionButtonClick(item)}
          className="px-[0px] w-auto text-sm"
          disabled={actionButtonsDisabled}
        >
          {item.action}
        </Button>
      ),
    },
  ];

  const getActionButtonLabel = (
    status: string | undefined,
  ): ThirdfortRow["action"] => {
    switch (status) {
      case "pending":
        return "abort";
      case "failed":
        return "reorder";
      case "aborted":
        return "reorder";
      case "completed":
        return undefined;
      default:
        return "order";
    }
  };

  useEffect(() => {
    if (transaction && thirdfortChecks) {
      const rows = persons.map((p) => {
        const personChecks = thirdfortChecks.find(
          (x) =>
            x.person_details.email === p.email &&
            x.person_type === p.person_type,
        );

        const formatPersonPhoneNumber = (phoneNumber: string): string => {
          return phoneNumber.startsWith("0")
            ? phoneNumber.replace(/^0/, "+44")
            : phoneNumber;
        };

        return {
          data: {
            name: `${p.first_name} ${p.last_name}`,
            status: personChecks ? personChecks.status : "",
            action: getActionButtonLabel(personChecks?.status),
            _id: personChecks?._id,
            thirdfortPersonDetails: {
              ...p,
              phone_number: formatPersonPhoneNumber(p.phone_number),
            },
          },
        };
      });

      setRows(rows);
    }
  }, [thirdfortChecks, transaction, persons]);

  return (
    <>
      {loading && <FullPageLoader />}
      <Card
        title={cardTitle}
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          <Table columns={columns} rows={rows} />
          {(error || thirdfortError) && (
            <ErrorText>{error || thirdfortError}</ErrorText>
          )}
        </>
      </Card>
    </>
  );
};
