import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type CreateThirdfort = paths["/api/thirdfort/{transactionId}"]["post"];

export type CreateThirdfortResponse =
  CreateThirdfort["responses"]["201"]["content"]["application/json"];

export type CreateThirdfortRequestBody =
  CreateThirdfort["requestBody"]["content"]["application/json"];

export const createThirdfortCheck = async (
  transactionId: string,
  newThirdfortCheck: CreateThirdfortRequestBody,
): Promise<CreateThirdfortResponse> => {
  try {
    const response = await api.post(
      `/thirdfort/${transactionId}`,
      newThirdfortCheck,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof AxiosError && error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
};
