import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type AbortThirdfortCheckResponse =
  paths["/api/thirdfort/{checkId}/abort"]["patch"]["responses"]["200"]["content"]["application/json"];

export const abortThirdfortCheck = async (
  checkId: string,
): Promise<AbortThirdfortCheckResponse> => {
  try {
    const response = await api.patch(`/thirdfort/${checkId}/abort`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error trying to abort thirdfort check");
  }
};
