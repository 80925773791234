import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type UpdateCompanyResponse =
  paths["/api/company/{id}"]["put"]["responses"]["200"]["content"]["application/json"];

export type UpdateCompanyRequest =
  paths["/api/company/{id}"]["put"]["requestBody"]["content"]["application/json"];

export const updateCompany = async (
  id: string,
  updatedCompany: UpdateCompanyRequest,
): Promise<UpdateCompanyResponse> => {
  try {
    const response = await api.put(`/company/${id}`, updatedCompany);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update user failed");
  }
};
