import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { AddTransactionsGroupName } from "./AddTransactionsGroupName";
import { FormikErrors } from "formik";

interface TransactionsGroupNameBlockProps<T> {
  submitTrigger: (trigger: () => void) => void;
  onError: (errors: FormikErrors<T> | undefined) => void;
}

export const TransactionsGroupNameBlock = <T extends object>({
  submitTrigger,
  onError,
}: TransactionsGroupNameBlockProps<T>) => {
  const { transactionsGroup } = useTransactionsGroupContext();

  const [fields, setFields] = useState<TableProps[]>([]);
  const [displayGroupNameForm, setDisplayGroupNameForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transactionsGroup) {
      const updatedFields = [
        { label: "Transactions Group Name", value: transactionsGroup.name },
      ];
      setFields(updatedFields);
    }
  }, [transactionsGroup]);

  return (
    <Card
      title="Transactions Group"
      backgroundColour="bg-brand-heavy-warmth-25"
    >
      {displayGroupNameForm || !transactionsGroup?.name ? (
        <AddTransactionsGroupName
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setDisplayGroupNameForm={setDisplayGroupNameForm}
          submitTrigger={submitTrigger}
          onError={onError}
        />
      ) : (
        <div className="w-full">
          <Card
            titleColor="brand-heavy-teal-100"
            titleBackgroundColor="brand-heavy-warmth-50"
            title="Name"
            titleRightContent={
              <button onClick={() => setDisplayGroupNameForm(true)}>
                Edit
              </button>
            }
          >
            <div>
              {fields.map(({ value, label }) => (
                <div key={label} className="flex jusify-top">
                  <Table key={value as string} label={label} value={value} />
                </div>
              ))}
            </div>
          </Card>
        </div>
      )}
    </Card>
  );
};
